import * as React from 'react'
import Layout from '../../layout'
import NamePage from '../../pagesComponents/login/name'

const Name = () => {
	return (
		<Layout title="Enter your name">
			<NamePage />
		</Layout>
	)
}

export default Name
